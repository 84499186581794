/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import AnalItem from "../AnalItem";

function Odvaga({
  datum,
  vrijeme,
  broj,
  roba,
  netto,
  company,
  napomena,
  noGutter,
  smjer,
  analiza,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const openSuccessSB2 = useCallback(
    (e) => navigate({ pathname: "/analysis/anal1", search: "?id=" + e.broj })
    // navigate({ pathname: "/analysis/anal1", params: { broj: e.broj } })
  );

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      py={1}
      px={2}
      my={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={0}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            color={smjer === "U" ? "dark" : "warning"}
          >
            {broj} {roba}
          </MDTypography>
          <MDBox
            display="flex"
            alignItems="center"
            mt={{ xs: 0, sm: 1 }}
            mb={{ xs: 1, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <Link
              to={"/analysis/anal1"}
              state={{ id: broj, roba: roba, company: company, napomena: napomena, netto: netto }}
            >
              {/*<Link to={"/test1/GoogleMaps"} state={{ id: broj, roba: roba }}>*/}
              <MDButton
                variant="contained"
                color={darkMode ? "white" : "dark"}
                //color={darkMode ? "primary" : "primary"}
                key={broj}
                // onClick={(e) => openSuccessSB2({ broj }, e)}
              >
                <Icon>edit</Icon>&nbsp;edit
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            <MDTypography variant="caption" fontWeight="medium">
              {datum} ({vrijeme})
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Netto:&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {netto} kg
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Partner:&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {company && company}
            </MDTypography>
          </MDTypography>
        </MDBox>
        {napomena && (
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Opis:&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {napomena}
              </MDTypography>
            </MDTypography>
          </MDBox>
        )}
        <AnalItem item={analiza} />
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Odvaga
Odvaga.defaultProps = {
  noGutter: false,
  netto: 0,
  company: "",
  napomena: "",
  smjer: "",
  analiza: null,
};

// Typechecking props for the Odvaga
Odvaga.propTypes = {
  datum: PropTypes.string.isRequired,
  broj: PropTypes.string.isRequired,
  roba: PropTypes.string.isRequired,
  netto: PropTypes.string,
  company: PropTypes.string,
  napomena: PropTypes.string,
  smjer: PropTypes.string,
  analiza: PropTypes.array,
};

export default Odvaga;
