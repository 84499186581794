/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";

// Data
import authorsTableData from "layouts/odvage/data/authorsTableData";
import projectsTableData from "layouts/odvage/data/projectsTableData";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Projects from "../status/components/Projects";
import PropTypes from "prop-types";
import { log10 } from "chart.js/helpers";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { readLokacija } from "../../components/APIService/apiService";

function Status({ children }) {
  const { columns, rows } = authorsTableData();
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [listLokacija, setListLokacija] = useState([]);
  const [lokacija, setLokacija] = useLocalStorage("location_state2", null);
  const [defaultLokacija, setDefaultLokacija] = useState(null);
  const [lokacijaID, setLokacijaID] = useState(null);

  const pero = [
    { loakcija_id: 9, mprijema_id: 21 },
    { loakcija_id: 9, mprijema_id: 29 },
  ];

  const handleLokacijaChange = (event, newValue) => {
    setLokacija(newValue);
    setDefaultLokacija(newValue);
    setLokacijaID(newValue ? newValue.oms : null);
  };

  async function fetchLokacija() {
    const result = await readLokacija();
    setListLokacija(result);
    if (localStorage.getItem("location_state2", null) === null)
      localStorage.setItem("location_state2", JSON.stringify(result[0] ? result[0] : null));
    setDefaultLokacija(JSON.parse(localStorage.getItem("location_state2", null)));
  }

  useEffect(() => {
    // console.log("useEffect");
    const fetchData = async () => {
      try {
        // setLoading(true);
        const result = await fetchLokacija();
        // console.log(result);
      } catch (error) {
        console.error("Error processing data:", error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1}>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="dark"*/}
          {/*      icon="speed"*/}
          {/*      title="Otvorene Odvage"*/}
          {/*      count={-1}*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "",*/}
          {/*        label: "",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="dark"*/}
          {/*      icon="weekend"*/}
          {/*      title="Bookings"*/}
          {/*      count={281}*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "+55%",*/}
          {/*        label: "than lask week",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      icon="leaderboard"*/}
          {/*      title="Today's Users"*/}
          {/*      count="2,300"*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "+3%",*/}
          {/*        label: "than last month",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="success"*/}
          {/*      icon="store"*/}
          {/*      title="Revenue"*/}
          {/*      count="34k"*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "+1%",*/}
          {/*        label: "than yesterday",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={3}>*/}
          {/*  <MDBox mb={1.5}>*/}
          {/*    <ComplexStatisticsCard*/}
          {/*      color="primary"*/}
          {/*      icon="person_add"*/}
          {/*      title="Followers"*/}
          {/*      count="+91"*/}
          {/*      percentage={{*/}
          {/*        color: "success",*/}
          {/*        amount: "",*/}
          {/*        label: "Just updated",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
        {children}
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <MDBox mb={3}>*/}
            {/*    <ReportsBarChart*/}
            {/*      color="info"*/}
            {/*      title="website views"*/}
            {/*      description="Last Campaign Performance"*/}
            {/*      date="campaign sent 2 days ago"*/}
            {/*      chart={reportsBarChartData}*/}
            {/*    />*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <MDBox mb={3}>*/}
            {/*    <ReportsLineChart*/}
            {/*      color="success"*/}
            {/*      title="daily sales"*/}
            {/*      description={*/}
            {/*        <>*/}
            {/*          (<strong>+15%</strong>) increase in today sales.*/}
            {/*        </>*/}
            {/*      }*/}
            {/*      date="updated 4 min ago"*/}
            {/*      chart={sales}*/}
            {/*    />*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <MDBox mb={3}>*/}
            {/*    <ReportsLineChart*/}
            {/*      color="dark"*/}
            {/*      title="completed tasks"*/}
            {/*      description="Last Campaign Performance"*/}
            {/*      date="just updated"*/}
            {/*      chart={tasks}*/}
            {/*    />*/}
            {/*  </MDBox>*/}
            {/*</Grid>*/}
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {/*<MDBox display="flex" alignItems="center" mb={2}>*/}
              {/*  <Autocomplete*/}
              {/*    sx={{ width: 300 }}*/}
              {/*    disableClearable*/}
              {/*    ListboxProps={{ style: { maxHeight: 250, overflow: "auto" } }}*/}
              {/*    options={listLokacija}*/}
              {/*    value={lokacija}*/}
              {/*    defaultValue={defaultLokacija}*/}
              {/*    // autoHighlight*/}
              {/*    autoSelect*/}
              {/*    loadingText={"Loading..."}*/}
              {/*    // onChange={(event, newValue) => {*/}
              {/*    //   // setTabValue(0);*/}
              {/*    //   setLokacija(newValue);*/}
              {/*    // }}*/}
              {/*    onChange={handleLokacijaChange}*/}
              {/*    // onInputChange={onLokacijaInputChange}*/}
              {/*    getOptionLabel={(option) => option.title}*/}
              {/*    // size="small"*/}
              {/*    // id={"Lokacija"}*/}
              {/*    // freeSolo*/}
              {/*    // fullWidth*/}
              {/*    renderInput={(params) => (*/}
              {/*      <MDInput*/}
              {/*        {...params}*/}
              {/*        inputProps={{ ...params.inputProps, readOnly: true }}*/}
              {/*        type="text"*/}
              {/*        label={"Lokacija"}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*    // filterOptions={(options, state) => options}*/}
              {/*    isOptionEqualToValue={(option, value) => option.id === value.id}*/}
              {/*  />*/}
              {/*</MDBox>*/}
              {/*<Projects lokacija={{ lokacija_id: 0, mprijema_id: 0 }} />*/}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox display="flex" alignItems="center" mb={2}>
                <Autocomplete
                  sx={{ width: 300 }}
                  disableClearable
                  ListboxProps={{ style: { maxHeight: 250, overflow: "auto" } }}
                  options={listLokacija}
                  value={lokacija}
                  defaultValue={defaultLokacija}
                  // autoHighlight
                  autoSelect
                  loadingText={"Loading..."}
                  // onChange={(event, newValue) => {
                  //   // setTabValue(0);
                  //   setLokacija(newValue);
                  // }}
                  onChange={handleLokacijaChange}
                  // onInputChange={onLokacijaInputChange}
                  getOptionLabel={(option) => option.title}
                  // size="small"
                  // id={"Lokacija"}
                  // freeSolo
                  // fullWidth
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      type="text"
                      label={"Lokacija"}
                    />
                  )}
                  // filterOptions={(options, state) => options}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </MDBox>
              {lokacija && <Projects lokacija={lokacija} />}
            </Grid>
            {/*{tables}*/}
            {/*<Grid item xs={12} md={6} lg={4}>*/}
            {/*  <OrdersOverview />*/}
            {/*</Grid>*/}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Setting default props
Status.defaultProps = {
  children: "",
};

// Typechecking props
Status.propTypes = {
  children: PropTypes.node,
};

export default Status;
