/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useMemo, useState } from "react";

// react-router components
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";

// Data
import authorsTableData from "layouts/odvage/data/authorsTableData";
import projectsTableData from "layouts/odvage/data/projectsTableData";
import OdvageList from "./components/OdvageList";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { readLokacija } from "../../components/APIService/apiService";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useLocation } from "react-router-dom";
import { CabinRounded } from "@mui/icons-material";
import { Card } from "@mui/material";

function Odvage({ children }) {
  const APP_AUTH_SLOT = "EK_APP";
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [tabValue, setTabValue] = useState(0);
  const [tabValue, setTabValue] = useSessionStorage("TAB1", { tab: 0, time: 0 });
  const [typeValue, setTypeValue] = useState({ type: "", time: 0 });
  // const [anal, setAnal] = useContext(analysisContext);
  const [listLokacija, setListLokacija] = useState([]);
  const [lokacija, setLokacija] = useLocalStorage("location_state", null);
  const [defaultLokacija, setDefaultLokacija] = useState(null);
  const [lokacijaID, setLokacijaID] = useState(null);
  const navigate = useNavigate();

  // Search input value state
  const [search, setSearch] = useState("");
  const [qsearch, setQSearch] = useState("");

  // const location = useLocation();
  // console.log(location.result);

  const handleOnSearchChange = (e) => {
    let value = e.currentTarget.value;
    setSearch(value);
    if (value.length >= 3) setQSearch(value);
    else setQSearch("");
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue({ tab: newValue, time: Date.now() });
    switch (newValue) {
      case 0:
        setTypeValue({ type: "o", time: Date.now() });
        break;
      default:
        setTypeValue({ type: "z", time: Date.now() });
        break;
    }
  };

  const handleonClickTab = (value) => {
    setTabValue({ tab: value, time: Date.now() });
    switch (value) {
      case 0:
        setTypeValue({ type: "o", time: Date.now() });
        break;
      default:
        setTypeValue({ type: "z", time: Date.now() });
        break;
    }
  };

  // let value = JSON.parse(localStorage.getItem(APP_AUTH_SLOT));
  if (localStorage.getItem(APP_AUTH_SLOT) === null) return <Navigate to="/" />;

  useEffect(() => {
    // console.log("useEffect");
    const fetchData = async () => {
      try {
        // setLoading(true);
        const result = await readLokacija();
        // console.log(result);
        setListLokacija(result);
        if (localStorage.getItem("location_state", null) === null)
          localStorage.setItem("location_state", JSON.stringify(result[0] ? result[0] : null));
        setDefaultLokacija(JSON.parse(localStorage.getItem("location_state", null)));
        setTabValue(JSON.parse(sessionStorage.getItem("TAB1")));
        setTypeValue(
          tabValue.tab === 0 ? { type: "o", time: Date.now() } : { type: "z", time: Date.now() }
        );
      } catch (error) {
        console.error("Error processing data:", error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleLokacijaChange = (event, newValue) => {
    setLokacija(newValue);
    setLokacijaID(newValue ? newValue.oms : null);
  };

  // console.log(lokacija);

  if (!typeValue.type) return;
  // console.log(typeValue);

  const t = Array.apply(null, { length: 40 }).map((e, i) => {
    return (
      <MDBox pt={0} pb={1} px={2} key={i}>
        {i}
      </MDBox>
    );
  });

  return (
    <DashboardLayout>
      {/*<DashboardNavbar absolute isMini />*/}
      <DashboardNavbar />
      <MDBox position="relative" mt={search}>
        <MDBox mb={3}>
          {/*{children}*/}
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={6} lg={4} mt={1} mx={2}>
                    <AppBar position="static">
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue.tab}
                        onChange={handleSetTabValue}
                      >
                        <Tab
                          label="Otvoreno"
                          onClick={() => handleonClickTab(0)}
                          icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}>
                              table_view
                            </Icon>
                          }
                        />
                        <Tab
                          label="Zatvoreno"
                          onClick={() => handleonClickTab(1)}
                          icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}>
                              table_view
                            </Icon>
                          }
                        />
                      </Tabs>
                    </AppBar>
                  </Grid>
                </Grid>
                <Grid mt={1} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <MDBox width="12rem" my={1} mx={2}>
                    <MDInput
                      type={"search"}
                      clearable={"true"}
                      placeholder="Search..."
                      value={search}
                      size="medium"
                      fullWidth
                      // onChange={({ currentTarget }) => {
                      //   setSearch(currentTarget.value);
                      //   // onSearchChange(currentTarget.value);
                      // }}
                      // onChange={(e) => setSearch(e.currentTarget.value)}
                      onChange={handleOnSearchChange}
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" my={1} mx={2}>
                    <Autocomplete
                      sx={{ width: 250 }}
                      disableClearable
                      ListboxProps={{ style: { maxHeight: 250, overflow: "auto" } }}
                      options={listLokacija}
                      value={lokacija}
                      defaultValue={defaultLokacija}
                      // autoHighlight
                      autoSelect
                      loadingText={"Loading..."}
                      // onChange={(event, newValue) => {
                      //   // setTabValue(0);
                      //   setLokacija(newValue);
                      // }}
                      onChange={handleLokacijaChange}
                      // onInputChange={onLokacijaInputChange}
                      getOptionLabel={(option) => option.title}
                      // size="small"
                      // lokacijaID={"Lokacija"}
                      // freeSolo
                      // fullWidth
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                          type="text"
                          label={"Lokacija"}
                        />
                      )}
                      // filterOptions={(options, state) => options}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </MDBox>
                </Grid>
              </Card>
              {/*{t}*/}
              <OdvageList lokacija={lokacija} type={typeValue} search={search} tab={{ tabValue }} />
            </Grid>
            {/*<Grid item xs={12} md={5}>*/}
            {/*  <Transactions />*/}
            {/*</Grid>*/}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Setting default props
Odvage.defaultProps = {
  children: "",
};

// Typechecking props
Odvage.propTypes = {
  children: PropTypes.node,
};

export default Odvage;
