/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import { Navigate } from "react-router-dom";

function Basic() {
  const APP_AUTH_SLOT = "EK_APP";
  /*
      clear localStorage so
      so auto login won't happen
    */
  localStorage.setItem(APP_AUTH_SLOT, null);
  localStorage.removeItem(APP_AUTH_SLOT);
  return <Navigate to="/" />;
}

export default Basic;
