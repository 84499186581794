/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import Anal1 from "layouts/analysis/components/Layout1";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { readAnal, readPartner, writePartner, writeOdvaga } from "components/APIService/apiService";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, TextField } from "@mui/material";
import * as React from "react";
import { useMaterialUIController } from "../../context";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

function Anal({ route }) {
  const APP_AUTH_SLOT = "EK_APP";

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [kvalError, setKvalError] = useState({ Vlaga: false, Primjesa: false, Hektolitar: false });
  const [kvalValue, setKvalValue] = useState([]);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [napomena, setNapomena] = useState(null);
  const [listVlasnik, setListVlasnik] = useState([]);
  const [listPrimatelj, setListPrimatelj] = useState([]);
  const [listIsporucitelj, setListIsporucitelj] = useState([]);
  const [vlasnik, setVlasnik] = useState(null);
  const [primatelj, setPrimatelj] = useState(null);
  const [isporucitelj, setIsporucitelj] = useState(null);
  const [defaultVlasnik, setDefaultVlasnik] = useState(null);
  const [defaultPrimatelj, setDefaultPrimatelj] = useState(null);
  const [defaultIsporucitelj, setDefaultIsporucitelj] = useState(null);
  const [vlasnikError, setVlasnikError] = useState(false);
  const [primateljError, setPrimateljError] = useState(false);
  const [isporuciteljError, setIsporuciteljError] = useState(false);
  const previousController = useRef();
  // const analiza = ["Vlaga", "Primjesa", "Hektolitar"];
  const [analiza, setAnaliza] = useState([{}]);
  // const [avatars, setAvatars] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [nazivValue, setNazivValue] = useState("");
  const [mjestoValue, setMjestoValue] = useState("");
  const [adresaValue, setAdresaValue] = useState("");
  const [nazivError, setNazivError] = useState(false);
  const [mjestoError, setMjestoError] = useState(false);
  const [adresaError, setAdresaError] = useState(false);
  const [noviPartner, setNoviPartner] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [okDisabled, setOkDisabled] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  if (cancel)
    navigate("/odvage", {
      result: "cancel",
    });

  const onButtonClick = () => {
    navigate("/odvage", {
      itemId: 86,
    });
  };

  async function handleSubmitAnaliza(event) {
    setOkDisabled(true);
    setLoading(true);
    await sleep(250);
    try {
      setVlasnikError(false);
      setPrimateljError(false);
      setIsporuciteljError(false);
      if (!vlasnik) {
        setVlasnikError(true);
        // return;
      }
      if (!primatelj) {
        setPrimateljError(true);
        // return;
      }
      if (!isporucitelj) {
        setIsporuciteljError(true);
        // return;
      }
      if (vlasnikError || primateljError || isporuciteljError || isKvalError()) {
        return;
      }
      const response = await writeOdvaga({
        id: null,
        vagarskilist: location.state.id,
        vlasnik_id: vlasnik.id,
        primatelj_id: primatelj.id,
        isporucitelj_id: isporucitelj.id,
        napomena: napomena,
        kval: kvalValue,
      });
      switch (response.result) {
        case "OK":
          navigate("/odvage", {
            result: "OK",
          });
          break;
        default:
          console.log(response.result);
          break;
      }
    } catch (error) {
      console.error("Error processing data:", error);
    } finally {
      setLoading(false);
      setOkDisabled(false);
    }
  }

  const isKvalError = () => Object.values(kvalError).includes(true);

  // console.log(isKvalError());

  async function handleSubmitPartner(event) {
    try {
      setNazivError(false);
      if (!nazivValue || nazivValue.length < 5) {
        setNazivError(true);
        return;
      }
      if (nazivError) {
        return;
      }
      const response = await writePartner({
        id: null,
        naziv: nazivValue.trim(),
        mjesto: mjestoValue.trim(),
        adresa: adresaValue.trim(),
      });
      // console.log(response);
      switch (response.result) {
        case "OK":
          setNoviPartner(false);
          break;
        case "Duplicate":
          break;
        default:
          // setNoviPartner(false);
          break;
      }
    } catch (error) {
      console.error("Error processing data:", error);
    }
  }

  // useEffect block
  useEffect(() => {
    // fetchData();
    // setKvalValue((kvalValue) => ({ Vlaga: 14 }));
    // console.log(kvalValue["Vlaga"]);
    setKvalValue({ ...kvalValue, id: location.state.id });
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await readAnal(location.state.id);
        // console.log(result);
        let a = [];
        let b = {};
        result.analiza.map(function (item) {
          a.push(item);
          b[item.fname] = item.value;
        });
        const vlas = await getPartnerData("id=" + result.header.vlasnik_id, "Vlasnik");
        const prim = await getPartnerData("id=" + result.header.primatelj_id, "Primatelj");
        const ispo = await getPartnerData("id=" + result.header.isporucitelj_id, "Isporucitelj");
        setAnaliza(a);
        setKvalValue(b);
        setDefaultVlasnik(vlas[0] ? vlas[0] : null);
        setVlasnik(vlas[0] ? vlas[0] : null);
        setDefaultPrimatelj(prim[0] ? prim[0] : null);
        setPrimatelj(prim[0] ? prim[0] : null);
        setDefaultIsporucitelj(ispo[0] ? ispo[0] : null);
        setIsporucitelj(ispo[0] ? ispo[0] : null);
      } catch (error) {
        console.error("Error processing data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <MDBox mt={2} justifyContent="center" sx={{ display: "flex" }}>
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  // const avatars = analiza.map(({ name, value, fname, min, max }) => {
  const avatars = Object.entries(analiza).map(([key, item]) => {
    // console.log(key, item, item.name, item.value);
    // console.log(kvalValue);
    // return;
    return (
      <MDBox mb={2}>
        <MDInput
          type="number"
          label={item.name}
          // defaultValue={kvalValue[name]}
          value={kvalValue[item.fname]}
          // error={passwordError}
          // fullWidth
          // value={password}
          error={kvalError[item.fname]}
          key={item.fname}
          inputProps={{
            "data-name": item.fname,
            "data-min": item.min,
            "data-max": item.max,
          }}
          onChange={(e) => handleChangeKval(e)}
          size={"small"}
          // endAdornment={<InputAdornment position="end">%</InputAdornment>}
          // onChange={handleChange}
          // onChange={e => /setFirstName(e.target.value)
          // onChange={(e) => setKvalValue((kvalValue) => ({ name: e.target.value }))}
          // onChange=setKvalValue({ name: value })
        />
      </MDBox>
    );
  });

  // const handleChange = (e) => {
  function handleChangeKval(e) {
    let value = e.target.valueAsNumber;
    value = isNaN(value) ? "" : value;
    let id = e.target.getAttribute("data-name");
    let min = Number(e.target.getAttribute("data-min"));
    let max = Number(e.target.getAttribute("data-max"));
    let test = true;
    if (!value.empty && value >= min && value <= max) {
      test = false;
    }
    test = value.length === 0 ? false : !(value >= min && value <= max);
    setKvalError({ ...kvalError, [id]: test });
    setKvalValue({ ...kvalValue, [id]: value });
  }

  function handleChange(e) {
    let value = e.target.value;
    let id = e.target.getAttribute("data-name");
    setKvalValue({ ...kvalValue, [id]: value });
  }

  const getPartnerData = async (searchTerm, control) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    const result = await readPartner(searchTerm);
    const updatedOptions = result.map(({ id, naziv }) => {
      return { id: id, title: naziv };
    });
    switch (control) {
      case "Vlasnik":
        setListVlasnik(updatedOptions);
        break;
      case "Primatelj":
        setListPrimatelj(updatedOptions);
        break;
      case "Isporucitelj":
        setListIsporucitelj(updatedOptions);
        break;
    }
    return updatedOptions;
  };

  const onPartnerInputChange = (event, value, reason) => {
    // console.log(event);
    // console.log(new Error().stack);
    value.trim();
    if (value && event) {
      getPartnerData(value, event.currentTarget.id);
    } else if (event) {
      switch (event.currentTarget.id) {
        case "vlasnik":
          setListVlasnik([]);
          break;
        case "primatelj":
          setListPrimatelj([]);
          break;
        case "isporucitelj":
          setListIsporucitelj([]);
          break;
      }
    }
  };

  if (localStorage.getItem(APP_AUTH_SLOT) === null) return <Navigate to="/" />;

  if (!avatars) return;

  const snpOn = () => setNoviPartner(true);
  const snpOff = () => setNoviPartner(false);
  const cancelON = () => setCancel(true);
  const okD = (state) => setOkDisabled(state);

  if (noviPartner)
    return (
      <Anal1>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Partner
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={3} px={3} textAlign="center">
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label={"Naziv"}
                  value={nazivValue}
                  fullWidth
                  error={nazivError}
                  key={"naselje"}
                  onChange={(e) => setNazivValue(() => e.target.value)}
                />
              </MDBox>
              <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={5}>
                  <MDBox mb={2} mr={1}>
                    <MDInput
                      type="text"
                      label={"Mjesto"}
                      value={mjestoValue}
                      fullWidth
                      error={mjestoError}
                      key={"mjesto"}
                      onChange={(e) => setMjestoValue(() => e.target.value)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={7}>
                  <MDBox mb={1}>
                    <MDInput
                      type="search"
                      label={"Ulica i broj"}
                      value={adresaValue}
                      fullWidth
                      error={adresaError}
                      key={"adresa"}
                      onChange={(e) => setAdresaValue(() => e.target.value)}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={4} mr={2}>
                  <MDBox mt={4} mb={1}>
                    {/*<MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>*/}
                    <MDButton variant="gradient" color="error" onClick={snpOff} fullWidth>
                      Cancel
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item xs={8}>
                  <MDBox mt={4} mb={1}>
                    {/*<MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>*/}
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e) => handleSubmitPartner(e)}
                      fullWidth
                    >
                      OK
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </Anal1>
    );

  return (
    <Anal1>
      <Card>
        <MDBox pt={2} pb={3} px={3} textAlign="center">
          <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {/*{searchParams.get("id")}*/}
              {location.state.roba}
            </MDTypography>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {/*{searchParams.get("id")}*/}
              {location.state.id}
            </MDTypography>
          </Grid>
          <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography variant="button" fontWeight="bold">
              Partner:&nbsp;
              <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                {location.state.company && location.state.company}
              </MDTypography>
            </MDTypography>
            <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
              {/*{searchParams.get("id")}*/}
              {location.state.netto} kg
            </MDTypography>
          </Grid>
          {location.state.napomena && (
            <Grid mb={0} item xs={12} sx={{ display: "flex" }} textAlign={"left"}>
              <MDTypography variant="button" fontWeight="bold">
                Opis:&nbsp;
                <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                  {location.state.napomena}
                </MDTypography>
              </MDTypography>
            </Grid>
          )}
          <MDBox component="form" role="form" mt={2}>
            {/*<MDBox display="flex" alignItems="center" mb={2}>*/}
            {/*  /!*<MDTypography variant="caption" color="secondary">*!/*/}
            {/*  /!* Vlasnik:&nbsp;&nbsp;*!/*/}
            {/*  /!*</MDTypography>*!/*/}
            {/*  <Autocomplete*/}
            {/*    // disableClearable*/}
            {/*    // value={pageSize.toString()}*/}
            {/*    options={entries}*/}
            {/*    // onChange={(event, newValue) => {*/}
            {/*    //   // setEntriesPerPage(parseInt(newValue, 10));*/}
            {/*    // }}*/}
            {/*    onChange={(event, newValue) => setValue(newValue)}*/}
            {/*    onInputChange={onPartnerInputChange}*/}
            {/*    getOptionLabel={(option) => option.title}*/}
            {/*    //size="medium"*/}
            {/*    freeSolo*/}
            {/*    // sx={{ width: "5rem" }}*/}
            {/*    fullWidth*/}
            {/*    renderInput={(params) => <MDInput {...params} type="text" label={"Vlasnik"} />}*/}
            {/*    filterOptions={(options, state) => options}*/}
            {/*    isOptionEqualToValue={(option, value) => option.id === value.id}*/}
            {/*  />*/}
            {/*</MDBox>*/}
            <Grid>
              <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={10}>
                  <MDBox display="flex" alignItems="center" mb={2}>
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: 250, overflow: "auto" } }}
                      options={listVlasnik}
                      value={vlasnik}
                      defaultValue={defaultVlasnik}
                      autoHighlight
                      loadingText={"Loading..."}
                      onChange={(event, newValue) => setVlasnik(newValue)}
                      onInputChange={onPartnerInputChange}
                      getOptionLabel={(option) => option.title}
                      size="small"
                      id={"Vlasnik"}
                      key={"Vlasnik"}
                      // freeSolo
                      fullWidth
                      renderInput={(params) => (
                        <MDInput {...params} error={vlasnikError} type="text" label={"Vlasnik"} />
                      )}
                      filterOptions={(options, state) => options}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      // inputValue={inputVlasnik}
                      //autoSelect={true}
                      // defaultValue={vlasnik}
                      // value={listVlasnik.find((option) => option.value === option)}
                      //input={"PG HOLJENKO DRAŽEN"}
                      // defaultValue={listVlasnik[0] ? listVlasnik[0].title : null}
                      // loading={true}
                      // onChange={(event, newValue) => {
                      //   // setEntriesPerPage(parseInt(newValue, 10));
                      // }}
                      //sx={{ overflow: "auto" }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox ml={1}>
                    {/*<MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>*/}
                    <MDButton
                      variant="contained"
                      color={darkMode ? "white" : "dark"}
                      size={"small"}
                      onClick={snpOn}
                      fullWidth
                    >
                      +
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={10}>
                  <MDBox display="flex" alignItems="center" mb={2}>
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: 250, overflow: "auto" } }}
                      options={listPrimatelj}
                      value={primatelj}
                      defaultValue={defaultPrimatelj}
                      autoHighlight
                      loadingText={"Loading..."}
                      onChange={(event, newValue) => setPrimatelj(newValue)}
                      onInputChange={onPartnerInputChange}
                      getOptionLabel={(option) => option.title}
                      size="small"
                      id={"Primatelj"}
                      key={"Primatelj"}
                      // freeSolo
                      fullWidth
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          error={primateljError}
                          type="text"
                          label={"Primatelj"}
                        />
                      )}
                      filterOptions={(options, state) => options}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={10}>
                  <MDBox display="flex" alignItems="center" mb={2}>
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: 250, overflow: "auto" } }}
                      options={listIsporucitelj}
                      value={isporucitelj}
                      defaultValue={defaultIsporucitelj}
                      autoHighlight
                      loadingText={"Loading..."}
                      onChange={(event, newValue) => setIsporucitelj(newValue)}
                      onInputChange={onPartnerInputChange}
                      getOptionLabel={(option) => option.title}
                      size="small"
                      id={"Isporucitelj"}
                      key={"Isporucitelj"}
                      // freeSolo
                      fullWidth
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          error={isporuciteljError}
                          type="text"
                          label={"Isporučitelj"}
                        />
                      )}
                      filterOptions={(options, state) => options}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label={"Napomena"}
                  // value={napomena}
                  fullWidth
                  size="small"
                  // error={napomenaError}
                  key={"napomena"}
                  onChange={(e) => setNapomena(() => e.target.value)}
                />
              </MDBox>
            </Grid>
            {/*<MDBox mb={1}>*/}
            {avatars}
            {/*<MDBox mt={4} mb={1}>*/}
            {/*  /!*<MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>*!/*/}
            {/*  <MDButton*/}
            {/*    variant="gradient"*/}
            {/*    color="info"*/}
            {/*    onClick={(e) => handleSubmitAnaliza(e)}*/}
            {/*    fullWidth*/}
            {/*  >*/}
            {/*    OK*/}
            {/*  </MDButton>*/}
            {/*</MDBox>*/}
            <Grid mb={0} item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid item xs={4} mr={2}>
                <MDBox mt={4} mb={1}>
                  {/*<MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>*/}
                  <MDButton variant="gradient" color="error" onClick={cancelON} fullWidth>
                    Cancel
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox mt={4} mb={1}>
                  {/*<MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>*/}
                  <MDButton
                    disabled={okDisabled}
                    variant="gradient"
                    color="info"
                    onClick={(e) => handleSubmitAnaliza(e)}
                    fullWidth
                  >
                    OK
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </Anal1>
  );
}

export default Anal;
