/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React context
import React from "react";

function AnalItem({ item }) {
  if (!item) return;

  const t = item.map(({ name, value, jm }) => {
    return (
      <MDTypography variant="caption" fontWeight="medium" ml={1}>
        {name}:&nbsp;&nbsp;
        <MDTypography variant="caption" fontWeight="medium" color={"text"}>
          {value} {jm}
        </MDTypography>
      </MDTypography>
    );
  });

  return (
    t && (
      <MDBox mb={1} lineHeight={0}>
        {/*<MDTypography variant="caption" color="text">*/}
        {/*  Analiza:{test}*/}
        {/*</MDTypography>*/}
        <MDTypography variant="caption" color="text">
          Analiza:{t}
        </MDTypography>
      </MDBox>
    )
  );
}

// Setting default values for the props of Odvaga
AnalItem.defaultProps = {
  item: null,
};

// Typechecking props for the Odvaga
AnalItem.propTypes = {
  item: PropTypes.array,
};

export default AnalItem;
