/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Billing page components
import Odvaga from "layouts/odvage/components/Odvaga";
import { readOdvaga } from "../../../../components/APIService/apiService";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

function OdvageList({ lokacija, search, type, tab }) {
  const [data, setData] = useState(null);
  const [t, setT] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      // console.log(type);
      try {
        setLoading(true);
        const result = await readOdvaga({
          lokacija: lokacija ? lokacija.id : 0,
          mprijema: lokacija ? lokacija.mprijema_id : 0,
          tid: lokacija ? lokacija.tid : 0,
          oms: lokacija ? lokacija.oms : 0,
          search: search,
          type: type.type,
        });
        // console.log(result);
        setData(result);
        const t = result.map(
          ({ datum, vrijeme, broj, netto, partner, roba, napomena, smjer, tab, analiza }) => {
            return (
              <MDBox pt={0} pb={1} px={2} key={broj}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Odvaga
                    key={broj}
                    datum={datum}
                    vrijeme={vrijeme}
                    roba={roba}
                    broj={broj}
                    netto={netto}
                    company={partner}
                    napomena={napomena}
                    smjer={smjer}
                    analiza={analiza}
                    noGutter={true}
                  />
                </MDBox>
              </MDBox>
            );
          }
        );
        setT(t);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [lokacija, search, type]);

  if (loading) {
    return (
      <MDBox justifyContent="center" sx={{ display: "flex" }}>
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  return <Card id="title-odvage">{t}</Card>;
}

// Setting default props
OdvageList.defaultProps = {
  // type: "o",
};

// Typechecking props
OdvageList.propTypes = {
  type: PropTypes.string,
  search: PropTypes.string,
  lokacija: PropTypes.object,
};

export default OdvageList;
