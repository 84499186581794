/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images

// Dashboard components
import { readStatus } from "../../../../../components/APIService/apiService";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export default function data({ lokacija }) {
  const [data1, setData1] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("useEffect");
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await readStatus({ lokacija });
        // console.log(result);
        setData1(result);
      } catch (error) {
        console.error("Error processing data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [lokacija]);

  // if (loading) {
  //   return (
  //     <MDBox justifyContent="center" sx={{ display: "flex" }}>
  //       <CircularProgress color="inherit" />
  //     </MDBox>
  //   );
  // }

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={image} name={name} size="sm" />*/}
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const test = {
    columns: [
      { Header: "Roba", accessor: "roba", width: "20%", align: "left" },
      { Header: "Ulaz DD", accessor: "ulaz_dd", width: "10%", align: "right" },
      { Header: "Izlaz DD", accessor: "izlaz_dd", align: "right" },
      { Header: "Ulaz", accessor: "ulaz", align: "right" },
      { Header: "Izlaz", accessor: "izlaz", align: "right" },
      { Header: "Ulaz UK", accessor: "ulaz_uk", align: "right" },
      { Header: "Izlaz UK", accessor: "izlaz_uk", align: "right" },
    ],

    rows: [
      {
        roba: <Company name="Pšenica" />,
        ulaz_dd: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            24.000 kg
          </MDTypography>
        ),
        izlaz_dd: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            &nbsp;
          </MDTypography>
        ),
        ulaz: (
          <MDTypography variant="caption" color="text" fontWeight="bold">
            20.000 kg
          </MDTypography>
        ),
        izlaz: (
          <MDTypography variant="caption" color="text" fontWeight="bold">
            &nbsp;
          </MDTypography>
        ),
        ulaz_uk: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            44.000 kg
          </MDTypography>
        ),
        izlaz_uk: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            &nbsp;
          </MDTypography>
        ),
      },
      {
        roba: <Company name="Ječam" />,
        ulaz_dd: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            24.000 kg
          </MDTypography>
        ),
        izlaz_dd: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            &nbsp;
          </MDTypography>
        ),
        ulaz: (
          <MDTypography variant="caption" color="text" fontWeight="bold">
            20.000 kg
          </MDTypography>
        ),
        izlaz: (
          <MDTypography variant="caption" color="text" fontWeight="bold">
            &nbsp;
          </MDTypography>
        ),
        ulaz_uk: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            44.000 kg
          </MDTypography>
        ),
        izlaz_uk: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            &nbsp;
          </MDTypography>
        ),
      },
      {
        roba: <Company name="Uljana Repica" />,
        ulaz_dd: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            24.000 kg
          </MDTypography>
        ),
        izlaz_dd: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            &nbsp;
          </MDTypography>
        ),
        ulaz: (
          <MDTypography variant="caption" color="text" fontWeight="bold">
            20.000 kg
          </MDTypography>
        ),
        izlaz: (
          <MDTypography variant="caption" color="text" fontWeight="bold">
            &nbsp;
          </MDTypography>
        ),
        ulaz_uk: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            44.000 kg
          </MDTypography>
        ),
        izlaz_uk: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            &nbsp;
          </MDTypography>
        ),
      },
      // {
      //   companies: <Company image={logoAtlassian} name="Add Progress Track" />,
      //   members: (
      //     <MDBox display="flex" py={1}>
      //       {avatars([
      //         [team2, "Romina Hadid"],
      //         [team4, "Jessica Doe"],
      //       ])}
      //     </MDBox>
      //   ),
      //   budget: (
      //     <MDTypography variant="caption" color="text" fontWeight="medium">
      //       $3,000
      //     </MDTypography>
      //   ),
      //   completion: (
      //     <MDBox width="8rem" textAlign="left">
      //       <MDProgress value={10} color="info" variant="gradient" label={false} />
      //     </MDBox>
      //   ),
      // },
      // {
      //   companies: <Company image={logoSlack} name="Fix Platform Errors" />,
      //   members: (
      //     <MDBox display="flex" py={1}>
      //       {avatars([
      //         [team1, "Ryan Tompson"],
      //         [team3, "Alexander Smith"],
      //       ])}
      //     </MDBox>
      //   ),
      //   budget: (
      //     <MDTypography variant="caption" color="text" fontWeight="medium">
      //       Not set
      //     </MDTypography>
      //   ),
      //   completion: (
      //     <MDBox width="8rem" textAlign="left">
      //       <MDProgress value={100} color="success" variant="gradient" label={false} />
      //     </MDBox>
      //   ),
      // },
      // {
      //   companies: <Company image={logoSpotify} name="Launch our Mobile App" />,
      //   members: (
      //     <MDBox display="flex" py={1}>
      //       {avatars([
      //         [team4, "Jessica Doe"],
      //         [team3, "Alexander Smith"],
      //         [team2, "Romina Hadid"],
      //         [team1, "Ryan Tompson"],
      //       ])}
      //     </MDBox>
      //   ),
      //   budget: (
      //     <MDTypography variant="caption" color="text" fontWeight="medium">
      //       $20,500
      //     </MDTypography>
      //   ),
      //   completion: (
      //     <MDBox width="8rem" textAlign="left">
      //       <MDProgress value={100} color="success" variant="gradient" label={false} />
      //     </MDBox>
      //   ),
      // },
      // {
      //   companies: <Company image={logoJira} name="Add the New Pricing Page" />,
      //   members: (
      //     <MDBox display="flex" py={1}>
      //       {avatars([[team4, "Jessica Doe"]])}
      //     </MDBox>
      //   ),
      //   budget: (
      //     <MDTypography variant="caption" color="text" fontWeight="medium">
      //       $500
      //     </MDTypography>
      //   ),
      //   completion: (
      //     <MDBox width="8rem" textAlign="left">
      //       <MDProgress value={25} color="info" variant="gradient" label={false} />
      //     </MDBox>
      //   ),
      // },
      // {
      //   companies: <Company image={logoInvesion} name="Redesign New Online Shop" />,
      //   members: (
      //     <MDBox display="flex" py={1}>
      //       {avatars([
      //         [team1, "Ryan Tompson"],
      //         [team4, "Jessica Doe"],
      //       ])}
      //     </MDBox>
      //   ),
      //   budget: (
      //     <MDTypography variant="caption" color="text" fontWeight="medium">
      //       $2,000
      //     </MDTypography>
      //   ),
      //   completion: (
      //     <MDBox width="8rem" textAlign="left">
      //       <MDProgress value={40} color="info" variant="gradient" label={false} />
      //     </MDBox>
      //   ),
      // },
    ],
  };

  const pero = data1;
  // console.log(test);
  // console.log(data1);

  if (!loading) return pero;
}
