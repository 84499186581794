/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as React from "react";
import { useState } from "react";
// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { fetchAuth } from "components/APIService/apiService"; // Import your API functions

function Basic() {
  const APP_AUTH_SLOT = "EK_APP";

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [data, setData] = useState(null);
  const [logedIn, setLogedIn] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const userIsLoggedIn = () => {
    // const loggedInUser = getLoggedInUser();
    //
    // if (loggedInUser !== null) {
    //   setUser(loggedInUser);
    //   return true;
    // }
    // setUser(null);
    return localStorage.getItem(APP_AUTH_SLOT) !== null;
  };

  // const onButtonClick = async () => {
  const onButtonClick = async () => {
    username.trim();
    password.trim();

    setUsernameError(false);
    setPasswordError(false);

    if ("" === username) {
      setUsernameError(true);
      return;
    }

    if ("" === password) {
      setPasswordError(true);
      return;
    }

    // const fetchDataa = async () => {
    //   try {
    //     const result = await fetchAuth(username, password);
    //     setData(result);
    //     console.log(JSON.stringify(result));
    //     localStorage.setItem(APP_AUTH_SLOT, JSON.stringify(result));
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    fetchData(username, password);
  };

  async function fetchData(username, password) {
    try {
      // console.log(username, password);
      const result = await fetchAuth(username, password);
      // console.log(JSON.stringify(result));
      if (result.message !== "success") {
        localStorage.setItem(APP_AUTH_SLOT, null);
        localStorage.removeItem(APP_AUTH_SLOT);
        setLogedIn(false);
        return;
      }
      localStorage.setItem(APP_AUTH_SLOT, JSON.stringify(result));
      setLogedIn(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  if (userIsLoggedIn()) {
    return <Navigate to="/status" />;
  }

  // // useEffect block
  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username"
                error={usernameError}
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                error={passwordError}
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton
                //       aria-label="toggle password visibility"
                //       onClick={handleClickShowPassword}
                //       onMouseDown={handleMouseDownPassword}
                //       edge="end"
                //     >
                //       {showPassword ? <VisibilityOff /> : <Visibility />}
                //     </IconButton>
                //   </InputAdornment>
                // }
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch disabled checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={onButtonClick} fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
