// apiService.js
import axios from "axios";
import { stringify } from "stylis";

const API_BASE_URL = "https://emplin.edvard.net/api/";
const APP_AUTH_SLOT = "EK_APP";
const storage = JSON.parse(localStorage.getItem(APP_AUTH_SLOT));
const api_key = storage !== null ? storage.jwt : "";

const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
  // withCredentials: false,
  // headers: { "Content-Type": "application/json; charset=UTF-8" },
  headers: {
    Authorization: `Bearer ${api_key}`,
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "application/json",
  },
  // headers: {
  //   "Content-type": "application/json",
  //   Accept: "application/json",
  // },
});

const fetchAuth = async (username, password) => {
  // console.log(username, password);
  try {
    const response = await apiService.post("/auth_login2.php", {
      username: username,
      password: password,
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const readOdvaga = async (data) => {
  try {
    const response = await apiService.post("/read_odvaga.php", JSON.stringify(data));
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const writeOdvaga = async (data) => {
  try {
    const response = await apiService.post("/write_odvaga.php", JSON.stringify(data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

const writePartner = async (data) => {
  try {
    const response = await apiService.post("/write_partner.php", JSON.stringify(data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

const readAnal = async (search) => {
  try {
    // console.log(search);
    const response = await apiService.post("/read_anal.php", {
      search: search,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const readStatus = async (data) => {
  try {
    // console.log(data);
    const response = await apiService.post("/read_status.php", JSON.stringify(data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

const readPartner = async (data) => {
  try {
    const response = await apiService.post("/read_partner.php", { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const readLokacija = async (data) => {
  try {
    const response = await apiService.post("/read_lokacija.php", { data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  fetchAuth,
  readOdvaga,
  writeOdvaga,
  readPartner,
  writePartner,
  readAnal,
  readLokacija,
  readStatus,
};
