/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function data() {
  const [data, setData] = useState(null);
  const [t, setT] = useState(null);

  const Odvaga = ({ broj, netto, partner }) => (
    <MDBox display="flex" alignItems="left" lineHeight={1}>
      {/*<MDAvatar src={image} name={name} size="sm" />*/}
      <MDBox ml={0} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {broj}
        </MDTypography>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {netto} kg
        </MDTypography>
        <MDTypography variant="caption">{partner}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "broj", accessor: "broj", width: "20%", align: "left" },
      { Header: "datum", accessor: "datum", align: "left" },
    ],
    rows: [{ t }],
  };
}
